import { StoreModule, MetaReducer, ActionReducer, Store } from '@ngrx/store';
import { EffectsModule, Effect, Actions, ROOT_EFFECTS_INIT, ofType } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '@app-env/environment';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';


export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
	return function newReducer(state, action): any {
		console.groupCollapsed(action.type);
		const prevState = { ...state };
		const nextState = reducer(state, action);
		console.log(`%c prev state`, `color: #9E9E9E; font-weight: bold`, prevState);
		console.log(`%c action`, `color: #03A9F4; font-weight: bold`, action);
		console.log(`%c next state`, `color: #4CAF50; font-weight: bold`, nextState);
		console.groupEnd();
		return nextState;
	};
}

export function stateSetter(reducer: ActionReducer<any>): ActionReducer<any> {
	return (state: any, action: any) => {
		if (action.type === 'SET_ROOT_STATE') {
			return action.payload;
		}
		return reducer(state, action);
	};
}

export const metaReducers: MetaReducer<{}>[] = [ stateSetter ];
if (!environment.production) metaReducers.push(logger);


@Injectable()
export class RootInitEffects {

	constructor(
		private _actions$: Actions,
		private _store: Store<any>,
		private _transferState: TransferState,
	) {}

	@Effect({ dispatch: false })
	init$ = this._actions$.pipe(
		ofType(ROOT_EFFECTS_INIT),
		tap(_ => {
			// console.log('RootInitEffects: init$');

			// NGRX transfer state
			const NGRX_STATE = makeStateKey('NGRX_STATE');

			if (this._transferState.hasKey<any>(NGRX_STATE)) {
				// we are in the browser and transferstate has a key for NGRX state.
				const state = this._transferState.get<any>(NGRX_STATE, null);
				this._transferState.remove(NGRX_STATE);
				this._store.dispatch({ type: 'SET_ROOT_STATE', payload: state });
				console.log('Got state from server', state);
			} else {
				// we are on the server, so we set up a transferstate callback to serialze ngrx state
				this._transferState.onSerialize(NGRX_STATE, () => {
					let state: any;
					this._store.subscribe((saveState: any) => {
						state = saveState;
					}).unsubscribe();
					console.log('Set for browser', state);
					return state;
				});
			}
		})
	);
}


export const NGRX_CONFIG = {
	imports: [
		StoreModule.forRoot(
			{},
			{
				metaReducers,
				runtimeChecks: {
					strictStateImmutability: true,
					strictActionImmutability: true,
					strictStateSerializability: true,
					strictActionSerializability: true,
				}
			},
		),
		EffectsModule.forRoot(
			[ RootInitEffects ]
		),
	]
};

if (!environment.production) {
	NGRX_CONFIG.imports.push(
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			// logOnly: environment.production, // Restrict extension to log-only mode
		}),
	);
}

