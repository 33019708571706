import { Pipe, PipeTransform } from '@angular/core';

import dayjs from 'dayjs';
import 'dayjs/esm/locale/hu';

import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

@Pipe({ name: 'dayjsFormatDate' })
export class DayJSFormatDatePipe implements PipeTransform {
	transform(value: string, format: string = 'YYYY. MMMM D.'): string {
		return dayjs(value).locale('hu').format(format);
	}
}

@Pipe({ name: 'dayjsFormatTime' })
export class DayJSFormatTimePipe implements PipeTransform {
	transform(value: string, format: string = 'HH:mm:ss'): string {
		return dayjs(value, 'HH:mm:ss').locale('hu').format(format);
	}
}
