import { NgModule } from "@angular/core";
import { SHARED_COMPONENTS } from "./components";
import { SHARED_DIRECTIVES } from "./directives";
import { SHARED_PIPES } from "./pipes";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { NgbCollapseModule } from "@ng-bootstrap/ng-bootstrap";
import { InlineSVGModule } from "ng-inline-svg-2";
import { RouterModule } from "@angular/router";
import {
	FontAwesomeModule,
	FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
import {
	faTimes,
	faCommentAltEdit,
	faLink,
	faPrint,
	faInboxIn,
	faEnvelope,
	faClipboard,
	faClipboardCheck,
	faPlayCircle,
} from "@fortawesome/pro-light-svg-icons";
import {
	faHeart as fasHeart,
	faHeartBroken as fasHeartBroken,
	faArrowAltLeft,
	faArrowAltRight,
} from "@fortawesome/pro-solid-svg-icons";
import {
	faHeart as farHeart,
	faExpandAlt,
	faCompressAlt,
	faExternalLink,
} from "@fortawesome/pro-regular-svg-icons";
import { faTwitter, faFacebook } from "@fortawesome/free-brands-svg-icons";

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		ReactiveFormsModule,
		FontAwesomeModule,
		InlineSVGModule,

		NgbCollapseModule,
	],
	declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
	exports: [
		...SHARED_COMPONENTS,
		...SHARED_DIRECTIVES,
		...SHARED_PIPES,

		NgbCollapseModule,
		FontAwesomeModule,
		InlineSVGModule,
	],
})
export class SharedModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(
			fasHeart,
			farHeart,
			fasHeartBroken,
			faExternalLink,
			faPlayCircle,
			faLink,
			faPrint,
			faInboxIn,
			faClipboard,
			faClipboardCheck,
			faEnvelope,
			faTwitter,
			faFacebook,
			faCommentAltEdit,
			faArrowAltLeft,
			faArrowAltRight,
			faTimes,
			faExpandAlt,
			faCompressAlt
		);
	}
}
