import { Injectable } from '@angular/core';
import { HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import * as Hammer from 'hammerjs';

// custom configuration Hammerjs
@Injectable()
export class HammerConfig extends HammerGestureConfig {
	override overrides = {
		swipe: { direction: Hammer.DIRECTION_ALL, cssProps: { userSelect: null } },
		pinch: { enable: false },
		rotate: { enable: false }
	};
}

export const HAMMER_CONFIG = {
	imports: [
		HammerModule,
	],
	providers: [
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: HammerConfig
		}
	]
};

