import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserTransferStateModule } from '@angular/platform-browser';

import * as Sentry from '@sentry/angular';

import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { environment } from '@app-env/environment';


const SENTRY_PROVIDERS = environment.production ? [
	{
		provide: ErrorHandler,
		useValue: Sentry.createErrorHandler({
			// showDialog: true,
		}),
	},
	{
		provide: Sentry.TraceService,
		deps: [ Router ],
	},
	{
		provide: APP_INITIALIZER,
		useFactory: () => () => {},
		deps: [ Sentry.TraceService ],
		multi: true,
	},
] : [];

@NgModule({
	imports: [
		AppModule,

		BrowserTransferStateModule,
	],
	providers: [
		...SENTRY_PROVIDERS
	],
	bootstrap: [ AppComponent ],
})
export class AppBrowserModule {}
