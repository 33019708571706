import { NotFoundComponent } from './404/404.component';
import { ErrorComponent } from './error/error.component';
// import { FooterComponent } from './footer/footer.component';

export const SHARED_COMPONENTS = [
	// FooterComponent,
	NotFoundComponent,
	ErrorComponent,
];

// export * from './footer/footer.component';
export * from './404/404.component';
export * from './error/error.component';
