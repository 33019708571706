import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@app/shared/shared.module';

import { NGRX_CONFIG } from './configs/ngrx.config';
import { HAMMER_CONFIG } from './configs/hammer.config';


@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		ReactiveFormsModule,

		...NGRX_CONFIG.imports,
		...HAMMER_CONFIG.imports,

		SharedModule,
	],
	declarations: [
	],
	exports: [
	],
	providers: [
		...HAMMER_CONFIG.providers,
	]
})
export class CoreModule {
	constructor(
		@Optional() @SkipSelf() parentModule: CoreModule,
	) {
		if (parentModule) {
			throw new Error('CoreModule has already been loaded. Import Core modules in the AppModule only.');
		}
	}
}
