import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { SharedModule } from "@app/shared/shared.module";
import { NotFoundComponent } from "@app/shared/components";

const appRoutes: Routes = [
	{
		path: "",
		loadChildren: () =>
			import("./home/home.module").then((m) => m.HomeModule),
	},
	{
		path: "**",
		component: NotFoundComponent,
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, {
			useHash: false,
			initialNavigation: "enabledBlocking",
			anchorScrolling: "enabled",
			scrollPositionRestoration: "top",
			// enableTracing: true
		}),

		SharedModule,
	],
	exports: [RouterModule],
	providers: [],
})
export class AppRoutingModule {}
