import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppBrowserModule } from '@app/app.browser.module';
import { environment } from './environments/environment';

// import { create } from 'rxjs-spy';
// if (!environment.production) {
// 	const spy = create();
// 	spy.log();
// }

if (environment.production) {
	enableProdMode();

	[
		'debug', 'log', 'info', 'warn', 'error',
		'group', 'groupCollapsed', 'groupEnd',
		'dir', 'dirxml', 'trace', 'profile'
	].forEach(method => window.console[method] = () => void 0);
}

platformBrowserDynamic()
	.bootstrapModule(AppBrowserModule, {
		// ngZone: 'noop'
	})
	.catch(err => console.error(err));
